import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import { Link } from 'gatsby'
import styled from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll'
import StyledButton from '../styles/StyledButton'


const StyledDiv  = styled.div`

display: flex;
justify-content: space-between;




.main-content {
  max-width: 700px;
  text-align: justify;
}

a {
  color: #BC5D2E;
  margin-bottom: 2em;
}


@media screen and (max-width: 878px) {
  display: flex;
  flex-direction: column;

  .main-content {
    max-width: 100vw;
   
  }


  figure {
    margin-top: 2rem;
    width: 240px;
    height: 320px;
  }
}


`


const About = ( ) => {

  const [value, setValue] = useState(0)


    useEffect(()=>{
            window.addEventListener('scroll',function(){
            setValue(this.pageYOffset)
        })
    
        return (()=>{
            window.removeEventListener('scroll', function(){
                setValue(this.pageYOffset)
            })
        })
    
    })

return (
  <Layout>
    <SEO title="About" />
    <h1 className="expand">About Me</h1>
    <StyledDiv>
    <div  className="main-content">
      <p>Hi, My name is Tonye Obomanu. </p>
       <p> Thank you for checking out my portfolio site.</p>
      
      <p>I am a designer and developer. I've been in the tech industry for over 5years. </p>

     <p> My interest in development began in my final year at the university, while studying 
       for a degree in electrical engineering. As part of my dissertation I worked on a project that 
       entailed the development of a formula app to solve some engineering problems. Whist developing 
       this app I became very interested in programming and development 
       in general and decided to mainly focus on these aspects of the Tech Industry from then on. </p>

     <p> There weren't many opportunities in my community at that time for web developers so I started 
       out as a service desk analyst for a multinational company. After a year, hoping to get more 
       opportunities as a web developer, I moved to England to pursue a master’s degree in enterprise 
       systems. The master’s program exposed me to different platforms and I was engaged in 
       developing lots of web applications. </p>

      <p>My interest in front-end development, although a build up from my interest in development 
        in general, stemmed from being exposed to the various areas of web development during my
         master’s program. Because of its focus on users, I was naturally inclined to front end 
         development and Front-end resonated with me as a developer. I am always interested in what 
         users see when they interact with technology, how they interact
         with applications and how these processes can be improved to give a remarkable user experience.</p>

     <p>After my second degree, I started work as a freelance Web Developer and have acquired the following core skills;  <br/>
       <b> HTML | CSS | SASS | Javascript | Reactjs | Gatsby | wordpress | webflow <br />
      GIT  | REST APIs | GraphQL </b></p>

      <p> Design tools <br/>
      <b>Figma | Affinity Designer</b> </p>

     <p> I am determined and I always look forward to new opportunities/projects and challenges as 
       I maintain my drive to learn and improve my skills continuously.</p>

      <p> I enjoy making new connections and look forward to working with you. </p> 

      <Link to="/Contact/">Contact me</Link> <br />

  
     { value && <StyledButton onClick={() => scrollTo('#top')}>Back to top</StyledButton> }

    </div>
    <div>
    <figure style={{boxShadow: '10px 10px #dd1c1a'}}>
          <Image alt="selfie"/>
    </figure>

     <p> Calgary, AB Canada</p>
     <p><a href="https://ca.linkedin.com/in/tonye-obomanu" target="_blank" rel="noopener noreferrer">LinkedIn</a></p>
     <p><a href="https://github.com/Tonye123" target="_blank" rel="noopener noreferrer">Github</a></p>
    </div>

    
    </StyledDiv>
   
  </Layout>
)

}

export default About
